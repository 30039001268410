import React from 'react'
import { SectionFeaturedIntegrations } from '../../../types/sections'
import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import { Box, Button, Flex, Heading } from '../../../atoms'
import { IntegrationCard } from '../../resources/integrations'
import { SECTION_MARGIN } from '../../../utils/constants'
import { FeaturedIntegrationsRow, FeaturedIntegrationsCol } from './FeaturedIntegrations.styled'

function FeaturedIntegrations(props: SectionFeaturedIntegrations): JSX.Element {
  const { sectionId, id, headline, button, integrations } = props
  return (
    <Box as={'section'} id={sectionId ?? id} my={SECTION_MARGIN}>
      <Grid>
        <Row>
          <Col xs={12}>
            <Flex
              justifyContent={'space-between'}
              width={'100%'}
              alignItems={['flex-start', 'flex-start', 'flex-end']}
              gap={24}
              flexDirection={['column', 'row', 'row']}
            >
              <Heading as='h2' type='heading1'>
                {headline}
              </Heading>
              {button && button[0] && (
                <Button buttonType={button[0].buttonType} link={button[0].link} label={button[0].label} />
              )}
            </Flex>
          </Col>
        </Row>
        <Box mt={[6, 7, 8]}>
          <FeaturedIntegrationsRow className={'noscrollBar'} style={{ rowGap: '32px' }}>
            {integrations.map((integration) => (
              <FeaturedIntegrationsCol key={integration.id} xs={10} sm={5} md={3}>
                <IntegrationCard {...integration} />
              </FeaturedIntegrationsCol>
            ))}
          </FeaturedIntegrationsRow>
        </Box>
      </Grid>
    </Box>
  )
}

export default FeaturedIntegrations
